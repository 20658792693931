.hoverText1 {
  position: relative;
}

.hoverText1:before {
  content: '+';
  /* visibility: hidden;
    opacity: 0; */
  width: 34px;
  height: 34px;
  background: #2b3737;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  border-radius: 999px;
  cursor: pointer;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0px;
}

.sidebarfixcls {
  position: fixed !important;
}

.sidebarclass {
  width: 100% !important;
  z-index: 99 !important;
  background: #0000006e !important;
}
.sidecomponent-scroll {
  overflow-y: auto;
  height: 74vh;
}
.sidecomponent-scroll::-webkit-scrollbar {
  width: 7px !important;
  background-color: #f1f1f1;
  height: 7px !important;
}
.sidecomponent-scroll::-webkit-scrollbar-thumb {
  background: #ddd !important;
  border-radius: 0px 0px 4px 4px;
}

.sidecomponent-scroll2 {
  overflow-y: auto;
  height: 80vh;
}
.sidecomponent-scroll2::-webkit-scrollbar {
  width: 7px !important;
  background-color: #f1f1f1;
  height: 7px !important;
}
.sidecomponent-scroll2::-webkit-scrollbar-thumb {
  background: #ddd !important;
  border-radius: 0px 0px 4px 4px;
}
.sidebarclass::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.sidebarclass::-webkit-scrollbar-thumb {
  background: #ddd !important;
  border-radius: 0px 0px 4px 4px;
}
.sidebarclass::-webkit-scrollbar {
  width: 7px !important;
  background-color: #f1f1f1;
  height: 7px !important;
}

.sidebarclass::-webkit-scrollbar-thumb {
  background: #ddd !important;
  border-radius: 0px 0px 4px 4px;
}
.sideheader {
  margin: 0px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.flexsetjustify {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px !important;
  background: #cd3197 !important;
  color: #fff !important;
}

.px-20px {
  padding-left: 20px;
  padding-right: 20px;
}

.pt-10px {
  padding-top: 10px;
}

.tooltip-w {
  width: auto !important;
}

.submitbutton {
  width: 50%;
  background: var(--chakra-colors-primary) !important;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin: auto;
  height: 45px;
}

.inputcss {
  width: 100%;
  height: 38px;
  padding-left: 17px;
  padding-left: 17px;

  border: 1px solid #80808069;
  border-radius: 4px;
}

.mt-30px {
  margin-top: 30px;
}

.imageCss {
  width: 100%;
  height: 300px;
  margin-top: 10px;
  border: 2px dashed #80808069;
  border-radius: 4px;
}

.menucss-al {
  margin-right: 30px;
}

.mb-5px {
  margin-bottom: 5px;
}

.my-0px {
  margin-top: 0px;
  margin-bottom: 0px;
}


/*  */
.stop-abcall {
  position: inherit !important;
}

.stop-abchild {
  position: inherit !important;
}

.qkl-header {
  position: relative;
  z-index: 99;
}

/* .w-full {
  width: 100% !important;
} */

.sidebar-overlay {
  width: 38%;
  margin-left: auto;
  background: #f4f5f6;
  height: 100%;
}
@media (max-width: 505px) {
  .sidebar-overlay {
    width: 75% !important;
  }
}
.wihe {
  width: 100% !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.wihe2 {
  width: 60px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.bg-eb {
  background: #ebebeb !important;
}
.iconsetshre {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.input-lightgray {
  text-align: center;
  padding: 10px;
  border: 1px solid #8080805e;
  background-color: #fff;
}
.input-lightgrayt {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #8080805e;
}
.input-lightgray:focus {
  border: 1px solid #8080805e !important;
}
.text-gray {
  color: gray;
}

.rwt__tablist:not([aria-orientation='vertical'])
  .rwt__tab[aria-selected='true']:after {
  border-bottom: 4px solid var(--qtheme-color-primary) !important;
}
.hoverbg-primary:hover {
  background-color: var(--chakra-colors-primary);
  color: white;
}
.bg-primary {
  background-color: var(--chakra-colors-primary);
  color: white;
}
.select-cust {
  border: 1px solid #8080805e !important;
  padding: 5px 40px 5px 10px;
  border-radius: 4px;
}
.text-3d3b3b {
  color: #3d3b3b;
}
.rounded-4px {
  border-radius: 4px;
}
.coll-bordernone .accordion {
  border: none !important;
}
.coll-bordernone .accordion .accordion-item .title {
  background-color: #fff !important;
}
.coll-bordernone .accordion .accordion-item {
  margin-bottom: 1rem;
}

.coll-bordernone .accordion .accordion-item .title {
  border-radius: 8px !important;
}

.popup {
  position: fixed !important;
  width: 100% !important;
  z-index: 999999 !important;
  background: rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(10px);
  height: 100vh !important;
}
.activecard {
  border-bottom: 5px solid var(--chakra-colors-primary);
}
.bg-grays {
  background: #dedede4d !important;
}
.bg-ec {
  background: #ececec !important;
}
.bg-f4 {
  background: #f4f3f3 !important;
}
.hei-define {
  height: 60vh !important;
  overflow-y: auto !important;
}
.w-popup {
  width: 65% !important;
}
.tabs {
  padding: 10px 29px !important;
  border-radius: 6px 6px 0 0 !important;
}
.border-rr {
  border-radius: 7px 0 0 7px !important;
}
.border-ll {
  border-radius: 7px 0 7px 7px !important;
}
.tabs2 {
  padding: 7px 29px !important;
}
.h-img {
  height: 270px !important;
}
.h-img1 {
  height: 160px !important;
  object-fit: contain !important;
}
::-webkit-scrollbar {
  width: 10px !important;
}

.fsize27 {
  font-size: 27px !important;
}

@media (max-width: 640px) {
  .w-popup {
    width: 100% !important;
  }
  .tabs {
    padding: 4px 10px !important;
  }
  .sm-fsize12 {
    font-size: 12px !important;
  }
}
.height-270px {
  height: 270px !important;
}
.searchbtn-cs {
  border: 1px solid var(--qtheme-color-primary) !important;
  padding: 2px 10px;
  border-radius: 4px;
  color: var(--chakra-colors-primary);
  font-size: 14px;
}
.svgicon-gray {
  color: gray;
}
.bg-f4f5f6 {
  background-color: #f4f5f6 !important ;
}
.border-none .el-textarea__inner {
  border: 0px !important;
}
.dragcss {
  background: #cd3197;
  height: 10px;
}
.drag-seccss {
  background: #cd3197;
  width: 85px;

  height: 33px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  padding-top: 5px;
}
.cursor-allscroll {
  cursor: all-scroll;
}

.groupicons {
  background: #cd3197;
  width: 150px;
  height: 33px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
}

.elementui-css {
  position: relative;
}

.elementui-css::after {
  content: url('https://nexuscluster.blob.core.windows.net/server01/brained-product/brained-admin/editicon.svg');
  width: 40px;
  height: 30px;
  background: #cd3197;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  border-radius: 999px;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 9;
  left: 47%;
  top: -20px;
}
.elementui-css:hover::after {
  opacity: 1;
  transition: all 0.5s;
}
.elementui-css:hover {
  border: 4px solid #cd3197;
}
.bg-24Baa3 {
  background-color: #24baa3;
}
.cmstextarea .el-textarea__inner {
  height: 105px !important;
}
.cms-btnsuccess {
  padding: 8px 10px;
  background-color: #33ca5d;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
}
.cms-mediabtn {
  padding: 8px 10px;
  background-color: #33ca5d;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
}
.cms-btnrem {
  padding: 8px 10px;
  background-color: #24b4a2;
  border-radius: 5px;
  font-size: 14px;
  color: #fff;
}
.cmscolor-bg .el-textarea__inner {
  background-color: #999;
  color: #fff !important;
}
.manual-prui {
  padding: 0px !important;
  font-size: 12px !important;
}
.c-otpInput input {
  border: 1px solid gray;
  margin-right: 10px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 2px;
}
.c-otpInput input:focus-visible {
  outline: 0px !important;
}
.w-popuptr {
  width: 35%;
}
.wordbreak {
  word-break: break-word;
}

.imagecms-plus {
  position: relative;
}

.imagecms-plus::after {
  content: url('https://nexuscluster.blob.core.windows.net/server01/brained-product/brained-admin/editicon.svg');
  width: 40px;
  height: 27px;
  background: #cd31979e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  border-radius: 6px;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 999;
  left: 5px;
  top: 8px;
}
.imagecms-plus:hover::after {
  opacity: 1;
  transition: all 0.5s;
}

.dynamicForm {
  position: relative;
}

.dynamicForm::after {
  content: url(https://nexuscluster.blob.core.windows.net/server01/brained-product/brained-admin/editicon.svg)
    'Edit Form';
  width: 125px;
  height: 35px;
  background: #cd31979e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;

  opacity: 0;
  position: absolute;
  z-index: 999;
  left: 5px;
  top: 5px;
}

.dynamicForm:hover::after {
  opacity: 1;
  transition: all 0.5s;
}
.cms-uploader {
  border: 1px dashed gray;
  height: 200px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.text33ca5d {
  color: #33ca5d;
}
.c-orset {
  text-align: center;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  top: -12px;
}

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2rem;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.rwt__tab {
  position: relative;
}
.rwt__tablist:not([aria-orientation='vertical'])
  .rwt__tab[aria-selected='true']:after {
  border-bottom: 4px solid #cd3197 !important ;
}
.rwt__tab[aria-selected='true']:after {
  content: '';
  position: absolute;
}
.rwt__tablist:not([aria-orientation='vertical'])
  .rwt__tab[aria-selected='true']:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid #cd3197 !important ;
}
.w-38per {
  width: 38%;
}
/* photo rezise */

#photos {
  /* Prevent vertical gaps */
  line-height: 0;

  -webkit-column-count: 4;
  -webkit-column-gap: 0px;
  -moz-column-count: 4;
  -moz-column-gap: 0px;
  column-count: 4;
  column-gap: 0px;
}

#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
  padding: 8px;
  border-radius: 10px;
}
#photos img:hover {
  opacity: 0.7;
}
@media (max-width: 1200px) {
  #photos {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 1000px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 800px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 400px) {
  #photos {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
.input-lightgrayt:focus-visible {
  outline: 0px !important;
}
.remove-outline:focus-visible {
  outline: 0px !important;
}
.input-lightgray:focus-visible {
  outline: 0px !important;
}
.resp-height {
  height: 500px;
  overflow-y: auto;
  margin-top: 2rem;
}
.resp-height::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  border-radius: 999px !important;
} /* Track */
.resp-height::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.resp-height::-webkit-scrollbar-thumb {
  background: #88888894;
}

/* Handle on hover */
.resp-height::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.bg-brained {
  background: #cd3197 !important;
}
.text-brained {
  color: #cd3197 !important;
}
.text-lighgray {
  color: #5e5873 !important;
}
.modalcss-set .ReactModal__Overlay {
  z-index: 99;
}
.modalcss-set .ReactModal__Content {
  box-shadow: rgba(0, 0, 0, 0.15) -2px 2px 4px;
  border: none !important;
}
.chipslist-cs {
  padding: 10px 15px;
  background: #fff;
  border-radius: 4px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99;
  background-color: inherit !important;
}
.toggleswitch-css{
  position: fixed !important;
    z-index: 999;
    left: 15px;
    bottom: 35px;
}
.lighticon-color{
      background: #edf2f7;
}
.darkicon-color{
  background-color: #ffffff14;
  color: #b6b6b6 !important;
}

.active.fordarkmode {
  color: #fff !important;
}