@import '~react-image-gallery/styles/scss/image-gallery.scss';

.bg-primary {
  background-color: var(--chakra-colors-primary) !important;
}

.text-primary {
  color: var(--chakra-colors-primary) !important;
}

.bg-secondary {
  background-color: var(--chakra-colors-secondary) !important;
}

.text-secondary {
  color: var(--chakra-colors-secondary) !important;
}

.bg-tertiary {
  background-color: var(--chakra-colors-tertiary) !important;
}

.text-tertiary {
  color: var(--chakra-colors-tertiary) !important;
}

.bggradient-mid {
  background: radial-gradient(
    circle,
    var(--chakra-colors-primary) 3%,
    var(--chakra-colors-secondary) 70%
  );
}

.bggradient-50 {
  background: radial-gradient(
    circle,
    var(--chakra-colors-primary) 0%,
    var(--chakra-colors-secondary) 40%
  );
}

.bggradient-left {
  background: linear-gradient(
    234deg,
    var(--chakra-colors-secondary) 62%,
    var(--chakra-colors-primary) 99%
  );
}

.bggradient-right30 {
  background: linear-gradient(
    234deg,
    var(--chakra-colors-primary) 0%,
    var(--chakra-colors-secondary) 30%
  );
}

.bggradient-left30 {
  background: linear-gradient(
    234deg,
    var(--chakra-colors-secondary) 65%,
    var(--chakra-colors-primary) 100%
  );
}

//font
// @font-face {
//   font-family: 'poppins-regular';
//   src: url('../fonts/poppins/Poppins-Regular.ttf') format('opentype');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// small,
// label,
// span,
// li,
// button {
//   font-family: 'poppins-regular' !important;
// }

// body {
//   font-family: 'poppins-regular' !important;
// }

.text333 {
  color: #333333;
}

.text353535 {
  color: #353535;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.textwhite {
  color: white !important;
}

.lheight {
  line-height: 68px;
}

.trans-btn {
  background: inherit !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 0px !important;
}

.text030303 {
  color: #030303;
}

.bg-f4f5f6 {
  background-color: #f4f5f6;
}

// .cardimage {
//   background-image: url(../images//CardBg.svg);
// }
.text8787 {
  color: #878787;
}

.cardbgset {
  object-fit: contain !important;
  position: relative;
  top: -49px;
  height: 436px;
  width: 100%;
  left: 0px;
}

.banner-height {
  height: 505px;

  @media (max-width: 640px) {
    height: 290px !important;
  }
}

.banner-height2 {
  height: 605px;

  @media (max-width: 640px) {
    height: 290px !important;
  }
}

.chua-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.col-62 {
  color: #626262 !important;
}

.cust-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
}

.smcust-shadow {
  @media (max-width: 575px) {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
  }
}

@media (max-width: 640px) {
  .sm-w-25 {
    width: 25%;
  }

  .sm-w-100 {
    width: 100% !important;
  }

  .sm-w-60 {
    width: 60% !important;
  }

  .sm-w-70 {
    width: 70% !important;
  }

  .sm-w-75 {
    width: 75%;
  }

  .sm-rev-26 {
    padding: 20px 26px !important;
  }

  .sm-pt-4 {
    padding-top: 1rem;
  }

  .sm-pt-8 {
    padding-top: 2rem;
  }

  .sm-py-4 {
    padding: 1rem 0rem !important;
  }

  .sm-py-8 {
    padding: 2rem 0rem;
  }

  .sm-w-full {
    width: 100% !important;
  }

  .sm-mt-4 {
    margin-top: 1rem;
  }

  .sm-mb-4 {
    margin-bottom: 1rem;
  }

  .sm-px-30px {
    padding: 30px !important;
  }

  .sm-fsize16px {
    font-size: 16px !important;
  }

  .sm-fsize14 {
    font-size: 14px !important;
  }

  .sm-fsize12 {
    font-size: 12px !important;
  }

  .sm-h-screen {
    height: 100% !important;
  }

  .trans-btn {
    font-size: 14px !important;
  }

  .sm-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .sm-btntextwh {
    font-size: 14px !important;
    width: 150px !important;
    height: 45px !important;
  }

  .lheight {
    line-height: 42px;
  }

  .sm-none {
    display: none !important;
  }

  .sm-h250px {
    height: 250px !important;
  }

  .banner-height {
    height: 180px !important;
  }

  .cust-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .footer-menu {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .cust-grid4 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .front-img {
    width: 400px !important;
    height: 300px !important;
  }

  .back-img {
    width: 400px !important;
    height: 380px !important;
  }

  .front-img-div {
    inset: auto auto auto 2px !important;
    width: 220px !important;
    height: 300px !important;
  }

  .sm-h-150px {
    height: 150px !important;
  }
}

@media (max-width: 1024px) and (min-width: 641px) {
  .cust-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .tb-fsize14 {
    font-size: 14px !important;
  }

  .tb-wfull {
    width: 100% !important;
  }

  .cust-grid4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .footer-menu {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .tb-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .lheight {
    line-height: 48px !important;
  }

  .banner-height {
    height: 280px;
  }

  .tb-fsize23 {
    font-size: 23px !important;
  }

  .tb-h280px {
    height: 280px !important;
  }

  .abstop {
    position: absolute !important;
    top: 0 !important;
    border-radius: 20px !important;
  }
}

@media (min-width: 1024px) {
  .abstop {
    position: absolute !important;
    top: 0 !important;
    border-radius: 20px !important;
  }
}

.menuHover:hover {
  font-weight: 700;
  border-bottom: 2px solid var(--chakra-colors-primary);
}

.w-230px {
  width: 230px;
}

.clr-d9d9d9 {
  color: #d9d9d9;
}

.bg-d9d9d9 {
  background-color: #d9d9d9;
}

.clr-5a5a5a {
  color: #5a5a5a;
}

.clr-fff {
  color: #fff !important;
}

.lh24px {
  line-height: 24px;
}

.lineClam4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.input-email {
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  padding: 0px 6px 0px 26px;
  width: 100%;
  height: 48px;
}

.submit-email {
  padding: 11px 38px;
  border-radius: 30px;
}

span.chakra-button__icon.css-1hzyiq5 {
  margin-left: 0px !important;
}

.css-r6z5ec {
  width: 100% !important;
}

.phoneinput .form-control {
  width: 100% !important;
  padding: 0.5rem 14px 0.5rem 50px !important;
}

.phoneinputDark .form-control {
  width: 100% !important;
  padding: 0.5rem 14px 0.5rem 50px !important;
  background-color: transparent !important;
  border: 1px solid #454a53 !important;
  border-radius: 0.375rem !important;
  color: #ffffffeb !important;
}

.react-tel-input .country-list {
  width: 460px !important;
}

.verifyemail {
  background: #6a2c70;
  padding: 3px 10px;
  border-radius: 5px;
  color: #fff;
  top: 16px;
  cursor: pointer;
}

.resendotp-t [type='text'] {
  border: 1px solid #a6adb778;
  font-size: 14px;
  border-radius: 6px;
  width: 40px !important;
  height: 40px !important;
}

.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}

.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 300px;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
}



.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px;
}

.textred {
  color: red;
}

.custcolor:hover {
  color: var(--chakra-colors-primary);
}

@media (max-width: 640px) {
  .mob-hidden {
    display: none !important;
  }
}

@media (min-width: 641px) {
  .web-hidden {
    display: none;
  }
}

.custfixed {
  position: fixed;
  z-index: 9999;
}

.navbar {
  height: 60px;
  background-color: #fef7e5;
  position: relative;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 59px;
    background-color: #fff;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 260px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

.cutimage.chakra-wrap__listitem {
  margin: auto !important;
}

.flecol .chakra-wrap__list {
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  .custcolrev {
    flex-direction: column-reverse;
  }
}

.css-r6z5ec {
  width: 0% !important;
}

// dropdown css

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  right: 0px;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.br-40px {
  border-radius: 40px;
}

.border-6px {
  border: 6px solid #fff;
}

.hero-header {
  font-size: 40px;
  line-height: 54px;
  white-space: normal;
  font-weight: 600;
}

// height
.h-680px {
  height: 680px;
}

.h-580px {
  height: 580px;
}

.h-450px {
  height: 450px;
}

.h-400px {
  height: 400px;
}

.h-350px {
  height: 350px;
}
.h-280px {
  height: 280px;
}

.h-120px {
  height: 120px;
}

.padresponsive .marquee {
  display: flex !important;
  justify-content: space-evenly !important;
}

@media (max-width: 640px) {
  .padresponsive {
    padding-top: 70px;
  }
}

.img-custom {
  width: 100%;
  height: 100px;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.wid-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.marque2 {
  top: -70px;
  height: 160px;
  right: auto;
  bottom: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 25px 10px;
  padding: 40px 0px;
  background-color: white;
  border-radius: 8px;
}

.pb-180px {
  padding-bottom: 180px;
}

.card {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: 0px solid var(--chakra-colors-primary);
}

// .card:hover {
//   border-top: 10px solid var(--chakra-colors-primary);
// }

.card-img {
  width: 60px;
  height: 60px;
}

.card-img2 {
  border: 0px solid var(--chakra-colors-primary);
  border-radius: 16px;
  height: 350px;
  width: 100%;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 6px,
    rgb(225, 225, 225) 0px 0px 0px 7px;
}

.card-img2:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  border-bottom: 10px solid var(--chakra-colors-primary);
}

.testimonial-card {
  padding: 24px;
  // background-color: #ffffff;
  // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

.testimonial-div {
  width: 90px;
  height: 80px;
}

.testimonial-div1 {
  width: 100px;
  height: 80px;
}

.testimonial-img {
  width: 70px;
  height: 70px;
}

.zoom {
  transition: all 0.5s ease-in-out;
}

.zoom:hover {
  transform: scale(1.1);
}

.overflow-hidden {
  overflow: hidden !important;
}

.bg-ddd9d8 {
  background-color: #ddd9d8;
}

.bg-a2a2a2 {
  background-color: #a2a2a2;
}

.clr-04080C {
  color: #04080c;
}

.clr-ddd9d8 {
  color: #ddd9d8;
}

.br-ddd9d8 {
  border-color: #ddd9d8;
}

.bg-d2d2d2 {
  background-color: #d2d2d2;
}

.bg-EDF2F6 {
  background-color: #edf2f6;
}

.bg-c2bfbe {
  background-color: #c2bfbe;
}

.box-shadow-inset {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 4px inset;
}

.box-shadow-outer {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 4px;
}

.shadow-ffffff {
  box-shadow: 0 0px 0 6px #ffffff, 0 0px 0 7px #ddd9d8;
}

.shadow-ffffff:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.custShadow {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

.custShadow1 {
  box-shadow: rgba(0, 0, 0, 0.08) 2px 2px 18px 0px;
}

.custShadow1:hover {
  border-width: 8px 1px 1px 1px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 12px 8px;
}

.custShadow2 {
  box-shadow: rgba(0, 0, 0, 0.21) 0px 0px 6px 0px;
}

.fsize86 {
  font-size: 86px;
  line-height: 110px;
}

.contactcss {
  background-color: #fff;
  padding: 2rem;
  border-radius: 16px;
}

.letter-spacing4px {
  letter-spacing: 4px;
}

.inputFeild {
  padding: 6px 16px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  border-bottom: 1px solid #e4e8ec;
  outline: none;
  margin: 0px;
  background-color: #f4f5f6;
  font-size: 14px;
}

.inputFeild1 {
  padding: 6px 16px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  border: 2px solid #e4e8ec;
  outline: none;
  margin: 0px;
  background-color: #ddd9d8;
  font-size: 14px;
}

.form-button {
  color: #f7fbff;
  background-color: #c2bfbe;
}

.hover-6f6f6f:hover {
  border-left: 10px solid #6f6f6f;
}

.hover-ddd9d8:hover {
  border-left: 10px solid #ddd9d8;
}

.teams2-img {
  width: 160px;
  height: 160px;
}

.border-top12px {
  border-top: 12px solid #c2bfbe;
}

.br-28px {
  border-radius: 28px;
}

.br-16px {
  border-radius: 16px;
}

.w-h-330px {
  width: 380px;
  height: 330px;
}

.br-2px {
  border-width: 2px;
}

.br-2px:hover {
  border-color: #ddd9d8;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.numb-otp input {
  border: 1px solid gray;
  margin-right: 10px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px;
}

.fsize56 {
  font-size: 56px;
  line-height: 66px;
}

.leading-12 {
  line-height: 48px;
}

.back-img-div {
  border-top: 20px solid #c2bfbe;
  position: static;
  border-radius: 16px;
}

.back-img {
  width: 400px;
  height: 578px;
  border-radius: 0px;
  margin-top: -10px;
}

.front-img-div {
  inset: auto auto auto 135px;
  width: 314px;
  height: 438px;
  display: block;
}

.front-img {
  width: 314px;
  height: 438px;
  z-index: 1;
  border-radius: 16px;
  border-color: #ffffff;
  box-shadow: rgb(255, 255, 255) 4px 0px 0px 8px;
}

.fsize60 {
  font-size: 60px;
}

.fsize64 {
  font-size: 64px;
  line-height: 72px;
}

// .hr {
//   padding-bottom: 4px;
//   border-top: 1px solid #e4e8ec;
//   background-color: #c2bfbe;
//   margin-bottom: 24px;
//   width: 20%;
//   opacity: 90;
//   min-height: 10px;
// }

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 999999999 !important;
}

.topminus35 {
  top: -15% !important;
}

.clr-9c9c9c {
  color: #9c9c9c;
}

.minh450px {
  @media (min-width: 576px) {
    height: 450px;
  }
}

.minh500px {
  @media (min-width: 576px) {
    height: 500px;
  }
}

.bgg {
  background-color: rgba(255, 255, 255, 0.3);
}

.red-circle {
  width: 6px;
  height: 6px;
  background-color: var(--chakra-colors-primary) !important;
  border-radius: 100%;
}

.border1px-black {
  border: 1px solid black;
}

.border3px-fff {
  border: 3px solid #fff;
}

.rounded-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.rounded-left-lg {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.h45px {
  height: 45px !important;
}

input:hover {
  outline: 0px !important;
}

.h-120px {
  height: 120px !important;
}

.backdrop-filer {
  background: rgba(250, 250, 250, 0.5) !important;
  backdrop-filter: blur(10px) !important;
}

.backdrop-filer::placeholder {
  color: #000 !important;
}

// FAQ

.css-186l2rg {
  color: #9d4a4d !important;
}

//filter

.highlightOption {
  background: var(--chakra-colors-secondary) !important;
}

.multiSelectContainer li:hover {
  background: var(--chakra-colors-secondary) !important;
}

.chip {
  background: var(--chakra-colors-secondary) !important;
}

.cerashadow {
  box-shadow: rgba(34, 41, 47, 0.1) 0px 4px 24px 0px !important;
}

.menucheckii .css-r6z5ec {
  width: 100% !important;
  transform: translate3d(0px, 42px, 0px) !important;
  top: 22px !important;
}

.rem-rounded {
  border-radius: 0px !important;
}

// user-card

.minus3rem-mtop {
  margin-top: -3rem;
}

.w-10rem {
  width: 10rem;
}

.px-0px {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.bg-f4f4f4 {
  background-color: #f4f4f4;
}

.clr-515151 {
  color: #515151;
}

.clr-6f6f6f {
  color: #6f6f6f;
}

.clr-848484 {
  color: #848484;
}

.clr-ec1c24 {
  color: #ec1c24;
}

.css-1ss3utg[aria-selected='true'],
.css-1ss3utg[data-selected] {
  color: #fff !important;
  background: var(--chakra-colors-primary) !important;
  border-radius: 4px !important;
}

.css-1ss3utg {
  border-radius: 4px !important;
}

.bg-fff {
  background-color: #fff !important;
}

.fsize12px {
  font-size: 12px !important;
}

.maxheight350 {
  max-height: 350px !important;
}

.w-70px {
  width: 70px;
}

.bottom-border-ebebeb {
  border-bottom: 1px solid #ebebeb;
}

.lh-26px {
  line-height: 26px;
}

@media (max-width: 1024px) and (min-width: 641px) {
  .md-fsize18px {
    font-size: 18px !important;
  }

  .md-w-110px {
    width: 110px;
  }

  .md-w-60 {
    width: 60% !important;
  }

  .md-w-80 {
    width: 80%;
  }

  .md-w-90 {
    width: 90%;
  }

  .md-w-100 {
    width: 100%;
  }

  .md-h-100 {
    height: 100% !important;
  }

  .hero-header {
    font-size: 36px !important;
    line-height: 48px !important;
    font-weight: 600 !important;
  }

  .md-fsize64 {
    font-size: 64px;
    line-height: 80px;
  }
}

@media (max-width: 641px) {
  .sm-lh-22px {
    line-height: 22px !important;
  }

  .sm-drawer-padding {
    padding: 0px 5px 0px 5px !important;
  }

  .sm-ml-8px {
    margin-left: 8px !important;
  }

  .sm-minus4rem-mtop {
    margin-top: -4rem;
  }
}

.border6px-fff {
  border: 6px solid #fff;
}

.w-65 {
  width: 65%;
}

.min-w-65 {
  @media (min-width: 576px) {
    width: 65% !important;
  }
}

//testimonial
.testimonial .coverflow__text__39hqd {
  display: none !important;
}

.testimonial .coverflow__container__1P-xE {
  height: 145px !important;
  background: none !important;
}

.testimonial .coverflow__figure__3bk_C {
  box-shadow: none !important;
  margin: 0px 0px 0px 30px !important;
  -webkit-box-reflect: unset !important;

  @media (max-width: 640px) {
    width: 80px !important;
  }
}

.testimonial .coverflow__cover__25-7e {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
}

.testimonial .coverflow__coverflow__53z9A {
  margin-top: 1px !important;
}

.roundetest {
  @media (min-width: 576px) {
    width: 70px !important;
    height: 70px !important;
  }

  @media (max-width: 575px) {
    width: 50px !important;
    height: 50px !important;
  }
}

.fillstroke {
  stroke: #fdcc0d !important;
  fill: #fdcc0d !important;
}

.activeuser {
  border: 3px solid white;
}

//plans
.bg-plancard {
  border-radius: 10px;
  backdrop-filter: blur(17.5px);
  background: rgba(255, 255, 255, 0.25);
}

.bg-plancardwhite {
  border-radius: 3px;

  background: #fafafa;
}

.fitcontent {
  width: fit-content;
}

.planstop {
  @media (min-width: 576px) {
    margin-top: -140px;
  }
}

.borderblack {
  border: 1px solid #000;
}

.cardhover {
  transition: transform 0.2s;
}

.cardhover:hover {
  transform: scale(1.1);
  z-index: 99;
}

.active {
  color: var(--chakra-colors-primary);
}

.minmaxh280px {
  @media (min-width: 576px) {
    height: 280px !important;
  }

  @media (max-width: 575px) {
    height: 180px !important;
  }
}

.reactquill-h .ql-container {
  height: 200px;
}

.rwt__tab {
  font-size: 14px !important;
}

//elearning
.bg-1c1c1c {
  background: #1c1c1c;
}

@media (min-width: 641px) and (max-width: 821px) {
  .md-fsize30 {
    font-size: 30px;
  }

  .md-fsize20 {
    font-size: 20px;
  }

  .md-fsize40 {
    font-size: 40px;
  }

  .md-custdirection {
    display: flex;
    flex-direction: column-reverse;
  }
}

.bg-343434 {
  background: #343434;
}

.h-130px {
  height: 130px;
}

.h-200px {
  height: 200px;
}

.custcardcourse {
  border-radius: 0px 0px 10px 10px;
}

.custimghover:hover {
  transform: scale(1.1);
}

.h-300px {
  height: 300px;
}

.text-506380 {
  color: #506380;
}

.h-250px {
  height: 250px;
}

.text-727272 {
  color: #727272;
}

.bg-f7fbff {
  background: #f7fbff;
}

.bg-0b0b0b {
  background: #0b0b0b;
}

.bordbl {
  border-bottom: 1px solid #ececec !important;
}

.text-danger {
  color: red !important;
}

.text-dark {
  color: #111 !important;
}

.h-400px {
  height: 400px;
}

.inborder {
  border: 1px solid black !important;
}

.custpointwh {
  width: 40px;
  height: 40px;
}

@media (max-width: 640px) {
  .sm-custdirection {
    display: flex;
    flex-direction: column-reverse;
  }

  .sm-h-130px {
    height: 130px !important;
  }

  .sm-lineclamp-5 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
  }

  .sm-swiper .swiper-button-next:after {
    font-size: 25px !important;
  }

  .sm-swiper .swiper-button-prev:after {
    font-size: 25px !important;
  }

  .sm-wh {
    width: 40px;
    height: 28px;
  }

  .sm-update-button {
    font-size: 14px !important;
    padding: 10px !important;
  }

  .sm-h-fit {
    height: fit-content;
  }
}

.sm-fsize24 {
  @media (max-width: 640px) {
    font-size: 24px;
  }
}

.letter-spacing4px {
  @media (max-width: 640px) {
    letter-spacing: 0px !important;
  }
}

.sm-wfull {
  @media (max-width: 640px) {
    width: 100% !important;
  }
}

.sm-w-80 {
  @media (max-width: 640px) {
    width: 80% !important;
  }
}

.testimonial-div {
  @media (max-width: 640px) {
    display: flex !important;
    justify-content: center !important;
    width: 100%;
  }
}

.testimonial-div1 {
  @media (max-width: 640px) {
    display: flex !important;
    justify-content: center !important;
    width: 50%;
  }
}

.sm-h-250px {
  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.sm-h-350px {
  @media (max-width: 640px) {
    height: 350px !important;
  }
}

.smcust-btn {
  font-size: 14px !important;
  width: 150px !important;
  height: 45px !important;
}

.newswiper {
  @media (max-width: 640px) {
    width: auto !important;
  }
}

.newswiper {
  @media (min-width: 1024px) {
    width: 670px !important;
  }
}

.teams2-img {
  @media (max-width: 640px) {
    width: 100px !important;
    height: 100px !important;
  }
}

.card-img2 {
  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.sm-h-200px {
  @media (max-width: 640px) {
    height: auto !important;
  }
}

.sm-lh-32px {
  @media (max-width: 640px) {
    line-height: 32px !important;
  }
}

.sm-h-auto {
  @media (max-width: 640px) {
    height: auto !important;
  }
}

.hero-header {
  @media (max-width: 640px) {
    font-size: 24px !important;
    line-height: 32px !important;
    font-weight: 600 !important;
  }
}

.pb-180px {
  @media (max-width: 640px) {
    padding-bottom: 50px !important;
  }
}

.sm-fsize30 {
  @media (max-width: 640px) {
    font-size: 30px !important;
  }
}

.border-6d6d6d {
  border: 1px solid #6d6d6d;
}

// electronics home

.rounded-100px {
  border-radius: 100px !important;
}

.product-card {
  border: 1px solid #e9e9ed;
  border-radius: 8px;
  height: fit-content;
  display: block;
}

.product-card-image {
  min-width: 16px;
  min-height: 16px;
  display: inline-block;
  object-fit: contain;
  pointer-events: auto;
  width: 100%;
  height: 10rem;
  border-radius: 8px 8px 0px 0px;
}

.lineClamp1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.strikethrough {
  text-decoration: line-through !important;
}

// electronics product

.select-Filter {
  padding: 10px 6px;
  border-radius: 6px;
}

.h-100per {
  height: 100%;
}

.counter {
  width: 20%;
  display: flex;
  align-items: center;
  border: 1px solid hsla(0, 0%, 50.2%, 0.2901960784);
  border-radius: 6px;
  justify-content: center;
}

.counter-input {
  width: 40px;
  height: 20px;
  background: none;
  border: none;
  padding: 5px;
  text-align: center;
  font-size: 14px;
}

.chips-select {
  background: #ddd;
  padding: 5px 14px;
  font-size: 13px;
  border-radius: 50px;
  font-weight: 600;
  color: #5a5a5a;
  cursor: pointer;
}

.image-gallery-play-button {
  display: none;
}

.table-padding {
  padding: 1.5rem 0.75rem !important;
  line-height: 1rem !important;
}

.wrap-text {
  text-wrap: wrap;
}

.border-r-1 {
  border-right: 1px solid #e2e8f0;
}

.bordb-ececec {
  border-bottom: 1px solid #ececec !important;
}

// electronic cart

.boxshadow-cart {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 12px 0px;
}

.h-150px {
  height: 150px;
}

.lineClamp4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.counter1 {
  width: 25%;
  display: flex;
  align-items: center;
  border: 1px solid hsla(0, 0%, 50.2%, 0.2901960784);
  border-radius: 6px;
  justify-content: center;
}

.bdr-rev {
  border: 1px solid gray;
  padding: 16px 18px;
  border-radius: 8px;
}

.h-file {
  height: 56px;
}

.h-file {
  height: 56px;
}

.file-absl {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 80px;
  width: 40px;
}

.btn-30 {
  padding: 20px 30px !important;
}

.pad-y-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.btn-30 {
  padding: 20px 30px !important;
}

.pad-y-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

@media (max-width: 640px) {
  .sm-w-90 {
    width: 90%;
  }

  .sm-h-full {
    width: 100%;
  }

  .h-580px {
    height: 250px !important;
  }

  .fsize64 {
    font-size: 20px;
    line-height: 22px;
  }
  .sm-lh-30 {
    line-height: 30px !important;
  }
  .my-1rem {
    margin: 1rem 0rem;
  }

  .leading-12 {
    line-height: 26px;
  }

  .sm-pb-1 {
    padding-bottom: 0.25rem;
  }

  .sm-pb-2 {
    padding-bottom: 0.5rem;
  }

  .sm-pb-4 {
    padding-bottom: 1rem;
  }

  .sm-col-rev {
    flex-direction: column-reverse;
  }

  .sm-lh-24 {
    line-height: 22px;
  }

  .mt-8px {
    margin-top: 8px;
  }

  .mob-hidden {
    display: none;
  }

  .web-hidden {
    display: block !important;
  }

  .w-70 {
    width: 100%;
  }

  .my-1rem {
    margin: 1rem 0rem;
  }

  .sm-h-350px {
    height: 350px;
  }

  .sm-py-6 {
    padding: 1.5rem 0rem;
  }

  .leading-12 {
    line-height: 26px;
  }

  .sm-pb-1 {
    padding-bottom: 0.25rem;
  }

  .sm-pb-2 {
    padding-bottom: 0.5rem;
  }

  .sm-pb-4 {
    padding-bottom: 1rem;
  }

  .sm-col-rev {
    flex-direction: column-reverse;
  }

  .mt-8px {
    margin-top: 8px;
  }

  .mob-hidden {
    display: none;
  }

  .sm-justify-center {
    justify-content: center;
  }
}

.web-hidden {
  display: block !important;
}

.filtercard {
  background: white;
  margin: 0px 0px 0px 0px;
  padding: 8px 8px 8px 8px;
  border-radius: 10px;
  border: 1px solid #e9e9ed;
}

.css-1kfu8nn {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ml-10px {
  margin-left: 10px;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.mb-4px {
  margin-bottom: 4px !important;
}

.ml-10px {
  margin-left: 10px;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.chakra-checkbox__label {
  font-size: 14px !important;
}

.ml-10px {
  margin-left: 10px;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 36px;
  width: 20px;
}

.btn-30 {
  padding: 20px 30px !important;
}

.btn-30 {
  padding: 20px 30px !important;
}
@media (max-width: 768px) {
  .chakra-modal__content {
    margin-left: 10px;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .counter {
    width: 35%;
  }

  .chips-select {
    background: #ddd;
    padding: 10px 18px;
    font-size: 14px;
    border-radius: 50px;
    font-weight: 600;
    color: #5a5a5a;
    cursor: pointer;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 36px;
    width: 20px;
  }

  .counter1 {
    width: 35%;
  }
  .md-h-250px {
    height: 250px;
  }
  .md-font-25 {
    font-size: 22px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-30 {
    font-size: 30px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-25 {
    font-size: 22px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-30 {
    font-size: 30px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-lh-24 {
    line-height: 24px;
  }

  .md-font-14 {
    font-size: 14px;
  }

  .md-w-70 {
    width: 70%;
  }

  .md-font-25 {
    font-size: 22px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-30 {
    font-size: 30px !important;
  }

  .md-font-28 {
    font-size: 28px !important;
  }

  .md-font-14 {
    font-size: 14px;
  }

  .md-lh-24 {
    line-height: 24px;
  }

  .md-w-30 {
    width: 30%;
  }

  .md-w-70 {
    width: 70%;
  }

  .btn-30 {
    padding: 20px 30px !important;
  }

  .md-h-150px {
    height: 150px !important;
  }

  .md-h-350px {
    height: 350px !important;
  }
}

.add-btn {
  font-size: 12px !important;
  padding: 20px 28px !important;
}

.add-btn {
  font-size: 12px !important;
  padding: 20px 28px !important;
}

.fsize64 {
  font-size: 40px;
  line-height: 48px;
}

.chips-select {
  font-size: 14px;
}

.leading-12 {
  line-height: 34px;
}

.contactcss {
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
}

.fsize64 {
  font-size: 40px;
  line-height: 48px;
}

.chips-select {
  font-size: 14px;
}

.leading-12 {
  line-height: 34px;
}

.contactcss {
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 36px;
  width: 20px;
}

.btn-30 {
  padding: 20px 30px !important;
}

.height-fit {
  height: fit-content;
}

.pr-9rem {
  padding-right: 9rem !important;
}

.w-100per {
  width: 100% !important;
}

.wishlist-card {
  box-shadow: rgba(0, 0, 0, 0.25) 1.63px 1.63px 6px 0px;
  border-radius: 8px;
  height: fit-content;
  display: block;
}

.wishlist-card-image {
  min-width: 16px;
  min-height: 16px;
  display: inline-block;
  object-fit: cover;
  pointer-events: auto;
  width: 100%;
  height: 240px;
  border-radius: 8px 8px 0px 0px;
}

.wishlist-x-icon {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 12px 0px;
  border-radius: 50%;
}

.web-hidden {
  display: none !important;
}

.css-12vdn2q {
  margin: 0px !important;
}

.header-3-profile-icon {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: auto !important;
  background: none !important;
}

.header-3-profile-icon .chakra-button__icon {
  color: white !important;
}

//sbilayout-css
.border-primary {
  border: 1px solid var(--chakra-colors-primary) !important;
}

.border-hr {
  border: 5px solid var(--chakra-colors-primary) !important;
  width: 100px !important;
}

.backbanner {
  background: linear-gradient(
    rgba(11, 118, 177, 1),
    rgba(84, 193, 255, 1)
  ) !important;
}

.swiper-wrapper {
  display: flex !important;
}

.textprimary {
  color: var(--chakra-colors-primary) !important;
}

.bgsecondary {
  background: var(--chakra-colors-secondary) !important;
}

.textsecondary {
  color: var(--chakra-colors-secondary) !important;
}

.border-primary {
  border: 1px solid #29166f !important;
}

.border-hr {
  border: 5px solid #7dd0ff !important;
  width: 25px !important;
}

.backbanner {
  background: linear-gradient(
    rgba(11, 118, 177, 1),
    rgba(84, 193, 255, 1)
  ) !important;
}

.bordb {
  border-bottom: 1px solid #fff !important;
}

.bordbl {
  border-bottom: 1px solid #ececec !important;
}

.dropdowns .chakra-menu__menu-list {
  min-width: auto !important;
}

.dropdowns .chakra-menu__menuitem {
  font-size: 13px !important;
  padding: 9px 30px 9px 10px !important;
  border-bottom: 1px solid #ececec !important;
}

.cards-about {
  width: 403px !important;
  height: 500px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    width: 300px !important;
    height: 350px !important;
  }

  @media (max-width: 640px) {
    width: 86% !important;
    height: 300px !important;
  }
}

.cards-home {
  height: 500px !important;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 400px !important;
  }

  @media (max-width: 640px) {
    height: 100% !important;
  }
}

.h-banner {
  height: 420px !important;

  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.list-dot {
  list-style: disc !important;
  margin-left: 20px !important;
}

@media (max-width: 640px) {
  .cust-reverse {
    flex-direction: column-reverse !important;
  }

  .sm-line-clamp3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

//  ========= Navbar Css =========
.side-btn {
  width: 35px !important;
  height: 35px !important;
  background: #ececec !important;
}

.h-100vh {
  height: 100vh !important;
}

.bg-navbar {
  background: #0000006c !important;
}

//  ========= Navbar Css =========

//  ========= Home Css =========
.home-h {
  width: 100% !important;
  height: 650px !important;

  @media (max-width: 640px) {
    height: 400px !important;
  }
}

.cards-img {
  height: 500px !important;
  margin-right: -30px;

  @media (max-width: 820px) and (min-width: 642px) {
    height: 400px !important;
  }

  @media (max-width: 640px) {
    height: 400px !important;
  }
}

.cards2-img {
  width: 400px !important;
  height: 500px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    width: 300px !important;
    height: 350px !important;
  }

  @media (max-width: 640px) {
    width: 90% !important;
    height: 300px !important;
  }
}

.absolute-cards {
  margin-top: -30px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    margin-top: -10px !important;
  }

  @media (max-width: 640px) {
    margin-top: -20px !important;
  }
}

.absolute2-cards {
  margin-top: -80px !important;
  margin-left: 30px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    margin-top: -10px !important;
    margin-left: 10px !important;
  }

  @media (max-width: 640px) {
    margin-top: -20px !important;
    margin-left: 26px !important;
  }
}

.col-79 {
  color: #797979 !important;
}

.col-9e {
  color: #9e9e9e !important;
}

.bg-fa {
  background: #fafafa !important;
}

.falgship-h {
  width: 100% !important;
  height: 500px !important;

  @media (max-width: 640px) {
    height: 430px !important;
  }
}

.discover-h {
  width: 100% !important;
  height: 500px !important;

  @media (max-width: 640px) {
    height: 280px !important;
  }
}

.iframe-h {
  height: 410px !important;

  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.impact-h {
  width: 100% !important;
  height: 400px !important;

  @media (max-width: 640px) {
    height: 500px !important;
  }
}

.chairman-h {
  width: 100% !important;
  height: 800px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    height: 100% !important;
  }

  @media (max-width: 640px) {
    height: 100% !important;
  }
}

.map-img {
  width: 100% !important;
  height: 550px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.border-side {
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;

  @media (max-width: 640px) {
    border-left: none !important;
    border-right: none !important;
  }
}

.form-img {
  width: 100% !important;
  height: 600px !important;

  @media (max-width: 640px) {
    height: 100vh !important;
  }
}

.address-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.bg-ffffffa8 {
  background: #ffffffbb !important;
}

//  ========= Home Css =========

//  ========= About Css =========
.foundation-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    height: 300px !important;
  }

  @media (max-width: 640px) {
    height: 280px !important;
  }
}

.vision-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    height: 300px !important;
  }

  @media (max-width: 640px) {
    height: 280px !important;
  }
}

.mission-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    height: 300px !important;
  }

  @media (max-width: 640px) {
    height: 280px !important;
  }
}

//  ========= About Css =========

//  ========= SBI Ace Css =========
.creating-img {
  width: 100% !important;
  height: 500px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= SBI Ace Css =========

//  ========= Asha Scholarship Css =========
.scholarship-img {
  width: 100% !important;
  height: 550px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    height: 300px !important;
  }

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= Asha Scholarship Css =========

//  ========= ILM Css =========
.ilm-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.objective-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= ILM Css =========

//  ========= Working Css =========
.working-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 768px) and (min-width: 642px) {
    height: 300px !important;
  }

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= Working Css =========

//  ========= Women Css =========
.women-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= Women Css =========

//  ========= Youth Css =========
.youth-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.fellowship-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.application-h {
  height: 450px !important;

  @media (max-width: 640px) {
    height: 300px !important;
  }
}

//  ========= Youth Css =========

//  ========= Leap Css =========
.overviews-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.support-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

//  ========= Leap Css =========

//  ========= Jivanam Css =========
.empowering-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 200px !important;
  }
}

.col-14 {
  color: #141414 !important;
}

//  ========= Jivanam Css =========

//  ========= Maintenance Css =========
.h-100vh {
  height: 100vh !important;

  @media (max-width: 640px) {
    height: 97% !important;
  }
}

.text-a5 {
  color: #a5deff !important;
}

.z-ind-1 {
  z-index: 1;
  position: relative;
}

.maintenance-content {
  text-align: center;
}

.sbi-logo {
  width: 200px;
}

.bg-7DD0ff {
  background-color: #7dd0ff;
}

.bg-29166f {
  background-color: #29166f;
}

.col-29166f {
  color: #29166f;
}

.col-5c5c5e {
  color: #5c5c5e;
}

.border-dark {
  border: 1px solid #b8b8b8 !important;
}

.bg-d8 {
  background: #d8d8d8 !important;
}

.bg-f4 {
  background: #f4f4f4 !important;
}

.border-file {
  border: 5px solid rgba(0, 182, 240, 0.09) !important;
  background: rgba(231, 246, 255, 1);
}

.col-8c {
  color: #8c8c8c !important;
}

.font400we {
  font-weight: 400;
}

.image-swiper-button-prevs {
  position: absolute !important;
  z-index: 9999 !important;
  left: 5px !important;
  top: 44% !important;
  color: var(--qtheme-color-secondary) !important;
}

.image-swiper-button-nexts {
  position: absolute !important;
  z-index: 9999 !important;
  right: 5px !important;
  top: 44% !important;
  color: var(--qtheme-color-secondary) !important;
}
.image-swiper-button-prevz {
  position: absolute !important;
  z-index: 9999 !important;
  left: 5px !important;
  top: 35% !important;
  color: var(--qtheme-color-secondary) !important;
}

.image-swiper-button-nextz {
  position: absolute !important;
  z-index: 9999 !important;
  right: 5px !important;
  top: 35% !important;
  color: var(--qtheme-color-secondary) !important;
}
.mob-swiper .swiper-slide {
  width: auto !important;
}

.bg-no-repeat {
  background-repeat: no-repeat;
  background-size: cover;
}

.ttxbfg {
  height: 450px;
}

@media (max-width: 640px) {
  .mobtext-justify {
    text-align: justify !important;
  }
}

.file-icon {
  width: 70px !important;
  height: 60px !important;

  @media (max-width: 575px) {
    margin: auto !important;
    padding-top: 10px;
    width: 40px !important;
    height: 40px !important;
  }
}

.h-content {
  height: 350px !important;
  overflow-y: auto !important;
  padding: 26px !important;

  @media (max-width: 1400px) and (min-width: 769px) {
    height: 240px !important;
  }

  @media (max-width: 640px) {
    padding: 16px !important;
  }
}

.maintenanceImg {
  width: 100%;
  height: 100vh;
  background-image: url('https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Desktop%20-%202.png');

  @media (max-width: 575px) {
    width: 100%;
    background-image: url('https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Desktop%20-%203.png');
  }
}

.w-600px {
  width: 600px;

  @media (max-width: 575px) {
    width: 100%;
  }
}

.border-new {
  border-radius: 30px 30px 0 0 !important;

  @media (max-width: 768px) {
    border-radius: 0px !important;
  }
}

.border-new2 {
  border-radius: 15px 15px 0 0 !important;

  @media (max-width: 768px) {
    border-radius: 0px !important;
  }
}

.logos {
  width: 80px;
  height: 80px;

  @media (max-width: 640px) {
    width: 100% !important;
  }
}

.min-h475px {
  @media (min-width: 576px) {
    height: 475px !important;
  }
}

.bgadd-image {
  @media (min-width: 576px) {
    width: 100%;
    background-image: url('https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Ellipse%201.png');
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: unset;
    position: fixed;
    bottom: 0;
    height: 450px;
    background-size: cover;
  }

  @media (max-width: 575px) {
    width: 100%;
    background-image: url('https://nimbuscluster.blob.core.windows.net/server01/SBI%20Foundation/sbifoundation/sbifundation/Ellipse%202.png');
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: bottom;
    position: fixed;
    bottom: 0;
    background-position: unset;
    background-size: cover;
    height: 400px;
  }
}

.smfsize10 {
  @media (max-width: 575px) {
    font-size: 12px;
  }
}

//===========Footer Css=========//
.text-626262 {
  color: #626262;
}

.text-959595 {
  color: #959595;
}

.text-29166f {
  color: #29166f;
}

.text-cb {
  color: #cbcbcb;
}

.text-black {
  color: #000 !important;
}

//  ========= Maintenance Css =========//

//  ========= Swiper Css =========//
.direction
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 50% !important;
  bottom: 50% !important;
  right: 4px !important;
  left: auto !important;
  width: auto !important;
  display: grid !important;
  gap: 6px !important;
}

//  ========= Swiper Css =========//
.error-img {
  width: 400px !important;
  height: 400px !important;
}

.error-404 {
  width: 500px !important;
  height: 500px !important;
}

.mar-right {
  margin-right: -12% !important;
  margin-top: -4% !important;
}

.mar-left {
  margin-left: -12% !important;
  margin-top: -4% !important;
}

.contact-dot {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.filter-b4 {
  filter: brightness(0.5) !important;
}

.popup-img {
  height: 450px !important;

  @media (max-width: 575px) {
    height: 200px !important;
  }
}

// help support

.css-1u2cvaz {
  background: #e7e7e75e !important;
}

.border-accordion {
  border-width: 1px !important;
  border-radius: 6px !important;
}

.pd-6px {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.sel .css-161pkch {
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  padding: 10px 10px !important;
}

.edit_icon {
  background: #8f8f8f;
  color: #fff;
}

.clr-7b7b7b {
  color: #7b7b7b;
}

.react-reveal {
  opacity: 1 !important;
}

.rounded-3px {
  border-radius: 3px;
}

@media (max-width: 575px) {
  .sm-lead {
    line-height: 40px !important;
  }
}

.borderall {
  border: 1px solid #e9e9ed;
}

.list-notdot {
  list-style: none !important;
}

.cus-bannerdynamic::after {
  top: 116px !important;
}

.cus-featuredynamic::after {
  top: 46px !important;
}

//test & jasmeena

.cust-hw {
  height: 60px;
  width: 60px;
}

.bdr {
  border: 1px solid #e4e8ec;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: 'next';
  font-size: 30px !important;
  color: black;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: 'prev';
  font-size: 30px !important;
  color: black;
}

.star-hw {
  height: 16px;
  width: 16px;
}

.img-h {
  height: 600px;
}

.bg-fffaf0 {
  background: #fffaf0;
}

.absl {
  position: absolute;
  top: 30%;
}

.btn-bdr {
  border: 2px solid var(--chakra-colors-primary) !important;
}

.wid-70 {
  width: 70%;
}

.wid-30 {
  width: 30%;
}

.img-wh {
  width: 450px;
  height: 180px;
}

.cmnt-h {
  height: 105px;
}

.test-h {
  height: 210px;
}

.inp-bdr {
  border: 2px solid lightgray;
}

.h-750 {
  height: 750px;
}

.gal-img {
  height: 380px;
}

.steps {
  width: 35px;
  height: 35px;
  font-size: 14px;
  color: white;
  background: var(--chakra-colors-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.vert-line {
  border-left: 1px solid var(--chakra-colors-primary);
  position: absolute;
  left: 18px;
  height: 210px;
  top: 5px;
  z-index: -1;
}

.fab-wh {
  width: 100%;
  height: 450px;
}

.cat-wh {
  height: 220px;
}

.txt-absl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
}

.clr-fffaf0 {
  color: #fffaf0;
}

.bdr-r {
  border: solid var(--chakra-colors-primary);
  border-width: 2px 1px 2px 2px;
}

.bdr-l {
  border: solid var(--chakra-colors-primary);
  border-width: 2px 2px 2px 1px;
}

.hr-w {
  border-top-width: 2px;
  border-color: #da8f2c;
}

.w-10rem {
  width: 10rem;
}

.wid-20per {
  width: 20%;
}

.wid-80per {
  width: 80%;
}

@media (max-width: 640px) {
  .sm-overflow-x {
    overflow-x: scroll;
    width: 100%;
    display: -webkit-inline-box;
    padding-bottom: 0px;
  }

  .cust-hw {
    height: 50px;
    width: 50px;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 18px !important;
    color: black;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 18px !important;
    color: black;
  }

  .star-hw {
    height: 13px;
    width: 13px;
  }

  .img-h {
    height: 300px;
  }

  .absl {
    position: absolute;
    top: 20%;
  }

  .wid-70 {
    width: 100%;
  }

  .wid-30 {
    width: 100%;
  }

  .h-750 {
    height: 100%;
  }

  .gal-img {
    height: 250px;
    width: 100%;
  }

  .steps {
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: white;
    background: var(--chakra-colors-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding-bottom: 2px;
  }

  .w-5 {
    width: 15%;
  }

  .vert-line {
    border-left: 1px solid gray;
    position: absolute;
    left: 12px;
    height: 220px;
    top: 5px;
    z-index: -1;
  }

  .fab-wh {
    width: 100%;
    height: 250px;
  }

  .cat-wh {
    height: 160px;
  }

  .cmnt-h {
    height: 85px;
  }

  .bdr-r {
    border: solid var(--chakra-colors-primary);
    border-width: 1px 1px 0px 1px;
  }

  .bdr-l {
    border: solid var(--chakra-colors-primary);
    border-width: 1px 1px 1px 1px;
  }

  .hr-w {
    border-top-width: 1px;
    border-color: #da8f2c;
  }

  .sm-w-6rem {
    width: 6rem;
  }

  .sm-mx-auto {
    margin: 0px auto;
  }

  .sm-center {
    display: flex;
    justify-content: center;
  }

  .sm-wid-10per {
    width: 10%;
  }

  .sm-wid-90per {
    width: 90%;
  }

  .sm-btn-dual {
    padding: 8px 24px !important;
  }

  .sm-px-10px {
    padding: 0px 10px !important;
  }

  .sm-star-16 {
    width: 16px;
    height: 16px;
  }

  .sm-px-1rem {
    padding: 0 1rem !important;
  }

  .sm-px-05rem {
    padding: 0 0.5rem !important;
  }

  .sm-pb-6 {
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 820px) and (min-width: 641px) {
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 22px !important;
    color: black;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 22px !important;
    color: black;
  }

  .testimonial-img {
    width: 60px;
    height: 60px;
  }

  .letter-spacing4px {
    letter-spacing: 0px !important;
  }

  .md-wid-100 {
    width: 100%;
  }

  .img-h {
    height: 450px;
  }

  .absl {
    position: absolute;
    top: 16%;
  }

  .h-750 {
    height: 500px;
  }

  .gal-img {
    height: 300px;
    width: 100%;
  }

  .w-5 {
    width: 8%;
  }

  .vert-line {
    height: 235px;
  }

  .md-fab-wh {
    width: 100% !important;
    height: 300px !important;
  }

  .md-star-18 {
    width: 18px;
    height: 18px;
  }
  .creating-img {
    width: 100% !important;
    height: 450px !important;
  }
  .md-h-400px {
    height: 400px;
  }
  .md-h-450 {
    height: 450px !important;
  }
  .md-w-full {
    width: 100%;
  }
}

.flex_rating {
  display: flex !important;
}

.addcms_card {
  padding: 10px;
  border-radius: 4px;
  background: #fff;
}

.search-abs {
  position: absolute;
  right: 14px;
  top: 8px;
}

.bg-fafafa {
  background-color: #fafafa;
}

.bg-c3c3c3 {
  background-color: #c3c3c3;
}

.borderright {
  border-right: 1px solid;
}

// scv

.green-dot {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 100%;
}

.btn-dual {
  padding: 12px 36px !important;
}

.bgclr-black {
  background: black !important;
}

.clr-white {
  color: white !important;
}

.box-shdw {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.px-3rem {
  padding: 0 3rem;
}

.tab_css {
  background: #f4f5f6;
  padding: 10px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto;
  gap: 20px;
}

.c-flex {
  display: flex !important;
}

.menu_width180px {
  width: 180px !important;
}

.justifybetween {
  justify-content: space-between !important;
}

.h200px {
  height: 200px;
}

.txt-gray {
  color: gray !important;
}

// file-uploader

.uploader {
  border: 2px dashed #d9d9d9 !important;
  height: 180px !important;
  width: 100%;
  border-radius: 10px;
}

.uploader-input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
}

.h-180px {
  height: 180px;
}

.cust-bgf5f5f5 {
  background-color: #f5f5f5;
}

.modalcss {
  display: flex;
  background: #fff;
  height: 100% !important;
  align-items: center;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.h-chat {
  height: 600px !important;
  overflow-y: auto;

  @media (max-width: 640px) {
    height: 500px !important;
  }
}

.h-chatscroll {
  height: 500px !important;
  overflow-y: auto;

  @media (max-width: 640px) {
    height: 400px !important;
  }
}

.minus-dropdown {
  margin-top: -7px !important;
}

.circle {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important;
  box-shadow: 0 0 1 1 #0000001a !important;

  @media (max-width: 640px) {
    width: 50px !important;
    height: 50px !important;
  }
}

.circle-2 {
  width: 110px !important;
  height: 110px !important;
  border-radius: 50% !important;

  @media (max-width: 640px) {
    width: 60px !important;
    height: 60px !important;
  }
}

.pulse {
  animation: pulse-animation 4s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 50px rgba(0, 0, 0, 0);
  }
}

.pulse2 {
  animation: pulse2-animation 3s infinite;
}

@keyframes pulse2-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
}

.max-chat {
  max-width: 450px !important;
  border-radius: 10px 15px 0 10px !important;

  @media (max-width: 640px) {
    max-width: 250px !important;
  }
}

.max-chat2 {
  max-width: 450px !important;
  border-radius: 15px 10px 10px 0px !important;

  @media (max-width: 640px) {
    max-width: 250px !important;
  }
}

.w-250px {
  width: 250px;
}

.bg-ffefd0 {
  background: #ffefd0 !important;
}

.bg-e1e1e1 {
  background-color: #e1e1e1;
}

.custwidth {
  width: 100px;
  height: 75px;
}

.text-053c6d {
  color: #053c6d;
}

.csticon .chakra-popover__close-btn {
  color: #ff6600;
}

.custborder1 {
  border: 1px solid #d1d1d1;
  padding: 15px;
  border-radius: 10px;
}

.btnradius.chakra-button {
  border-radius: 100px !important;
  background: #ff6600 !important;
  color: white;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.preradius.chakra-button {
  border-radius: 100px !important;
  background: #053c6d !important;
  color: white;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.bg-e1e1e1 {
  background-color: #e1e1e1;
}
.image-block {
  position: relative;
  width: 100%;
  height: 450px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.12);
  transition: all 1.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  @media (max-width: 640px) {
    height: 350px;
  }
}
.image-block:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  .text-hover {
    opacity: 0;
    transform: scale(0.5);
  }
  .images-blog {
    transform: scale(2.85);
  }
  .figcap {
    bottom: 0;
  }
}
.text-hover {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  color: white;
  font-weight: 500;
  line-height: 1;
  opacity: 1;
  transform: scale(0.9);
  transition: 0.2s ease;
  z-index: 999;
}
img {
  height: 100%;
  transition: 0.25s;
}
.figcap {
  position: absolute;
  bottom: -59%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 30px;
  background-color: rgba(black, 0.5);
  color: white;
  line-height: 1;
  transition: 0.25s;
  @media (max-width: 640px) {
    bottom: -83%;
  }
}
.leading-para {
  line-height: 24px !important;
}

.heart {
  color: red;
}
.w-350px {
  width: 350px;
}
.filterr .css-r6z5ec {
  @media (max-width: 640px) {
    width: 65% !important;
  }
}

// Profile-2 css

.profile-img {
  width: 240px;
  height: 240px;
  overflow: hidden;
  margin: -180px auto 21px;
  border-radius: 20px;

  @media (max-width: 820px) and (min-width: 641px) {
    margin: 0 auto 21px;
  }

  @media (max-width: 640px) {
    margin: 0 auto 21px;
  }
}

.social-media-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  cursor: pointer;
}
.social-media-icon:hover {
  color: #fff !important;
  background: var(--chakra-colors-primary) !important;
}

.social-icon {
  flex: 0 0 auto;
  display: inline-block;
  width: 44px;
  height: 44px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  line-height: 44px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  transition: 0.3s;
  font-size: 22px;
  cursor: pointer;
}

.social-icon:hover {
  background-color: var(--chakra-colors-primary);
  color: #fff;
}

.pad-6px {
  padding: 6px;
}

.pad-10px {
  padding: 10px;
}

.fixed-side {
  position: sticky !important;
  margin-left: 0;
  border-radius: 20px;
  background-color: #fff;
  padding: 40px 30px;
  top: 200px;

  @media (max-width: 820px) and (min-width: 641px) {
    top: 0px;
    position: inherit !important;
  }

  @media (max-width: 640px) {
    padding: 30px 20px;
    top: 0px;
    position: inherit !important;
  }
}

.bg-f3f6f6 {
  background-color: #f3f6f6;
}

.bottom-border-e3e3e3 {
  border-bottom: 1px solid #e3e3e3;
}

.tab-size {
  width: 90px;
}

.tab-header {
  display: flex !important;
  justify-content: flex-end !important;
  border-bottom: none !important;

  @media (max-width: 640px) {
    display: flex !important;
    justify-content: space-between !important;
    border-bottom: none !important;
  }
}

.bg-fff4f4 {
  background-color: #fff4f4;
}

.bg-eef5fa {
  background-color: #eef5fa;
}

.black-dot {
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 100%;
}

.bg-f8fbfd {
  background-color: #f8fbfd;
}

.bg-e7ebeb {
  background-color: #e7ebeb;
}

.br-12px {
  border-radius: 12px;
}

.br-20px {
  border-radius: 20px;
}

.br-top-20px {
  border-radius: 20px 20px 0 0;
}

.br-bottom-20px {
  border-radius: 0 0 20px 20px;
}

.selected_tab[aria-selected='true'] {
  background-color: var(--chakra-colors-primary) !important;
}
.booking-img {
  height: 100vh !important;
  @media (max-width: 640px) {
    display: none;
  }
}
.clsss .css-13cymwt-control {
  line-height: 50px !important;
  @media (max-width: 640px) {
    line-height: 45px !important;
  }
}
.clsss .css-t3ipsp-control {
  line-height: 50px !important;
  @media (max-width: 640px) {
    line-height: 45px !important;
  }
}
.contact-area {
  padding: 60px 45px;
  border-radius: 20px;

  @media (max-width: 820px) and (min-width: 641px) {
    padding: 30px 20px;
  }

  @media (max-width: 640px) {
    padding: 30px 20px;
  }
}

.input-box {
  display: block;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #b5b5b5;
  background: transparent;
  height: 40px;
  // transition: .3s;
  // transform-origin: top;
  font-size: 14px;
}

.form-btn {
  font-size: 16px !important;
  font-weight: 500;
  display: inline-block;
  text-transform: capitalize;
  color: #1a1a1a !important;
  height: 50px !important;
  width: 133px !important;
  border: 1px solid #44566c !important;
  border-radius: 12px !important;
  background-color: #f8fbfd !important;
}

.form-btn:hover {
  background: var(--chakra-colors-primary) !important;
  border-color: transparent !important;
  color: #fff !important;
}

.btn-2 {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: black !important;
  background: #fff !important;
  line-height: 27px !important;
  padding: 12px 30px !important;
  border-radius: 10px !important;
  border: 1px solid black !important;
  transition: 0.3s !important;
}

.btn-2:hover {
  background: var(--chakra-colors-primary) !important;
  transform: translateY(-2px);
  border: 1px solid transparent !important;
  color: #fff !important;
}

.tab-title {
  background-color: #f3f6f6;
  border-radius: 12px;
  width: 84px;
  height: 84px;
  transition: 0.3s;
}

.tab-title1 {
  background-color: #f3f6f6;
  border-radius: 12px;
  width: 120px;
  height: 84px;
  transition: 0.3s;
}

.tab-title-active {
  background: var(--chakra-colors-primary) !important;
  color: #fff;
  border-radius: 12px;
  width: 84px;
  height: 84px;
  transition: 0.3s;
}

.tab-title-active1 {
  background: var(--chakra-colors-primary) !important;
  color: #fff;
  border-radius: 12px;
  width: 120px;
  height: 84px;
  transition: 0.3s;
}

.minwidth-70 {
  min-width: 70%;
}

.minheight-800 {
  min-height: 800px;

  @media (max-width: 640px) {
    min-height: 800px;
  }
}

.height-20rem {
  height: 20rem !important;
}

.advanced-cropper {
  background-color: white !important;
}
//teams7
.h-teams7 {
  height: 41.5rem !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 21.5rem !important;
  }
  @media (max-width: 640px) {
    height: 10.5rem !important;
  }
}
.featureimage {
  display: none;
}
.borderfeature {
  border: 2px solid #f3eaea;
}
.colorcard {
  color: #222;
}
.hover-images:hover .colorcard {
  color: #fff;
}
.hover-images:hover .borderfeature {
  border: none;
}
.hover-images:hover .featureimage {
  display: flex;
}
// Blogs css

.vr {
  border-right: 1px solid #9f9d9d;
}
.arrow-r {
  height: 20px;
  width: 20px;
}
.blog-abs {
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
  border-radius: 4px;
  padding: 6px;
}
.blg-hov:hover {
  background-color: var(--chakra-colors-primary);
  color: white;
}
.hr-blg {
  border-top-width: 2px;
  border-color: var(--chakra-colors-primary);
  margin: auto;
  width: 15%;
}

// Testimonial 8 css

.wh-20 {
  width: 20px;
  height: 20px;
}
.topr-abs {
  position: absolute;
  top: -40px;
  right: 10%;
  z-index: 1;
}
.img-wh-80 {
  height: 80px;
  width: 80px;
}
.w-90per {
  width: 90%;
}
.w-10per {
  width: 10%;
}
.img-wh-65 {
  width: 65px;
  height: 65px;
}
.img-wh-70 {
  width: 60px;
  height: 60px;
  @media (max-width: 641px) {
    width: 50px;
    height: 50px;
  }
}
.topc-abs {
  position: absolute;
  top: -35px;
  left: 0%;
}
.h-swipertestimonial {
  height: 300px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 280px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}
.h-swipertestimonial8 {
  height: 300px !important;
  @media (max-width: 768px) and (min-width: 641px) {
    height: 280px !important;
  }
  @media (max-width: 640px) {
    height: 100% !important;
  }
}
.gallerybox:hover:before {
  top: 0 !important;
}
.gallerybox:before {
  content: '' !important;
  background: var(--chakra-colors-primary);
  opacity: 0.5 !important;
  height: 50% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  top: -50% !important;
  transition: all 0.4s ease-in 0s;
  position: absolute;
}
.gallerybox:hover:after {
  bottom: 0 !important;
}
.gallerybox:after {
  content: '';
  background: var(--chakra-colors-primary);
  opacity: 0.5 !important;
  height: 50% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  bottom: -50% !important;
  position: absolute;
  transition: all 0.4s ease-in 0s;
}
.gallery-content {
  opacity: 0;
}
.gallerybox:hover .gallery-content {
  opacity: 1;
  transition: all 0.4s ease-in 0s;
}

.border {
  border: 1px solid #e2e8f0 !important;
  box-shadow: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}
.plus-opacity {
  opacity: 0;
}
.gallerybox:hover .plus-opacity {
  opacity: 0.6;
  transition: all 0.5s ease-in;
}
// button effect
.btn-effect {
  background: #ececec;
  color: var(--chakra-colors-primary);
  overflow: hidden;
}
.btn-effect:hover {
  position: relative;
  z-index: 7;
  color: #fff !important;
}
.btn-effect:hover:before {
  width: 110%;
  height: 200px;
  background: var(--chakra-colors-primary);
  left: 50%;
  color: #fff !important;
  top: 50%;
  z-index: -1;
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1);
}
.btn-effect:before {
  content: '';
  position: absolute;
  width: 0px;
  color: #fff !important;
  height: 0px;
  top: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  border-radius: 70%;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
}

.gradient:hover:after {
  bottom: 0 !important;
}
.gradient:after {
  content: '';
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  height: 100% !important;
  width: 100% !important;
  left: 0px !important;
  z-index: 1 !important;
  bottom: -60% !important;
  position: absolute;
  transition: all 0.2s ease-in 0s;
}
.text-gradient {
  display: none;
}
.gradient:hover .text-gradient {
  display: block;
  transition: all 0.1s ease-in 0s;
}
.text-4rem{
  font-size: 4rem;
}
.bckdrop{
      backdrop-filter: brightness(0.5);
}
.herovideo-height{
 
  @media(max-width:575px){
    height: 250px !important;
        object-fit: fill;
  }  
  
  @media(max-width:768px) {
      height: 400px;
      object-fit: fill;
    }
}