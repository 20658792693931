/* new styles added */

.swiper-button svg {
  width: 1.5rem;
  height: 1.5rem;
}

.image-swiper-button-prev {
  position: absolute !important;
  left: 5px;
  top: 44% !important;
  z-index: 9999 !important;
  color: var(--qtheme-color-secondary) !important;
}
.image-swiper-button-prevz {
  position: absolute !important;
  left: 5px;
  top: 35% !important;
  z-index: 9999 !important;
  color: var(--qtheme-color-secondary) !important;
}
.image-swiper-button-next {
  position: absolute !important;
  right: 5px;
  top: 44% !important;
  z-index: 9999 !important;
  color: var(--qtheme-color-secondary) !important;
}
.image-swiper-button-nextz {
  position: absolute !important;
  right: 5px;
  top: 35% !important;
  z-index: 9999 !important;
  color: var(--qtheme-color-secondary) !important;
}

.test-swiper-button-prev {
  position: absolute !important;
  left: 5px;
  top: 50% !important;
  z-index: 9999 !important;
  color: var(--qtheme-color-secondary) !important;
}

.test-swiper-button-next {
  position: absolute !important;
  right: 5px;
  top: 50% !important;
  z-index: 9999 !important;
  color: var(--qtheme-color-secondary) !important;
}

.swiper-button-disabled {
  opacity: 0.5;
}

.image-swiper-button-prevs {
  position: absolute !important;
  left: 7px !important;
  bottom: 25px !important;
  color: var(--qtheme-color-secondary) !important;
  z-index: 1;
}

.image-swiper-button-nexts {
  position: absolute !important;
  right: 7px !important;
  bottom: 25px !important;
  color: var(--qtheme-color-secondary) !important;
  z-index: 1;
}

.image-swiper-button-prevtest {
  position: absolute !important;
  left: 15px !important;
  bottom: 50% !important;
  color: var(--qtheme-color-secondary) !important;
  z-index: 1;
}

.image-swiper-button-nexttest {
  position: absolute !important;
  right: 15px !important;
  bottom: 50% !important;
  color: var(--qtheme-color-secondary) !important;
  z-index: 1;
}

.swiper-wrapper {
  align-items: center !important;
}